<template>
  <div class="flex px-3 py-1 bg-gray-700 rounded-md badge">
    <div class="pr-2 mr-2 border-r-2 border-blue-300 ">{{ badgeTitle }}</div>
    <div :style="valueStyle" class="font-semibold">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: ["badgeTitle", "value", "valueColor"],

  data() {
    return {
      valueStyle: {
        color: this.valueColor,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.badge {
  border: 1px solid rgba(#47b3fc, 0.5);
}
</style>
