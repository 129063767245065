<template>
  <div>
    <Setting />
  </div>
</template>

<script>
// @ is an alias to /src
import Setting from "@/components/SettingsPage.vue";

export default {
  name: "Settings",
  components: {
    Setting,
  },
};
</script>
