




















import { Component, Vue } from "vue-property-decorator";
import Backdrop from "@/components/shared/Backdrop.vue";

@Component({
  components: {
    Backdrop,
  },
})
export default class Modal extends Vue {}
