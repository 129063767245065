

























import { Component, Prop, Vue } from "vue-property-decorator";
import CustomButton from "@/components/shared/CustomButton.vue";
import Modal from "@/components/shared/Modal.vue";

@Component({
  components: {
    Modal,
    CustomButton,
  },
})
export default class TestModal extends Vue {
  @Prop() announcementId!: string;
  public cancel(): void {
    this.$emit("closeModal");
  }

  public deleteAnnouncement(): void {
    this.$store.dispatch("deleteAnnouncement", this.announcementId);
    this.$emit("closeModal");
  }
}
