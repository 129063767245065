<template>
  <div class="home">
    <PlayerComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import PlayerComponent from "@/components/Player.vue";

export default {
  name: "Player",
  components: {
    PlayerComponent,
  },
};
</script>
