



























































import { Component, Vue } from "vue-property-decorator";
import CustomButton from "@/components/shared/CustomButton.vue";
import Stat from "@/components/shared/Stat.vue";

@Component({
  components: {
    CustomButton,
    Stat,
  },
})
export default class RankInfo extends Vue {

}
