

































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import CustomButton from "@/components/shared/CustomButton.vue";
import Badge from "@/components/shared/Badge.vue";

@Component({
  computed: mapState(["vehicleList"]),
  components: {
    CustomButton,
    Badge,
  },
})
export default class VehicleDetails extends Vue {
  private vehicleId: string = this.$route.params.vehicleId;
  // eslint-disable-next-line
  private vehicle: any = [];
  // eslint-disable-next-line
  private image: any = {};
  // eslint-disable-next-line
  private images: any = [];
  private vehicleHasImages = false;

  created(): void {
    this.$store.dispatch("getAllVehicles");
  }

  mounted(): void {
    if (this.checkIfImageExists(this.vehicleId)) {
      this.image = this.images[0];
    }
  }

  computeOtlColor(val: number): string {
    if (val >= 2.0) {
      if (val == 99) {
        return "#2dc100";
      } else {
        return "#cb0000";
      }
    } else if (val >= 1.0) {
      return "#ffa22a";
    } else if (val >= 0) {
      return "#2dc100";
    } else {
      return "#f959ff";
    }
  }

  checkIfImageExists(vehid: string): boolean {
    let moreImages = true;
    let imageNumber = 1;
    while (moreImages) {
      try {
        // eslint-disable-next-line
        let vehicleItem: any = {};
        vehicleItem.src = require("../assets/vehicles/" +
          vehid +
          "_" +
          imageNumber +
          ".jpg");
        vehicleItem.id = imageNumber - 1;
        this.images.push(vehicleItem);
        imageNumber++;
        this.vehicleHasImages = true;
      } catch (e) {
        if (imageNumber === 1) {
          this.vehicleHasImages = false;
          return false;
        }
        moreImages = false;
      }
    }

    return true;
  }

  findVehicle(): void {
    for (var i = 0; i < this.$store.state.vehicleList.length; i++) {
      if (this.$store.state.vehicleList[i].vehicleId == this.vehicleId) {
        this.vehicle = this.$store.state.vehicleList[i];
      }
    }
  }

  updateSelectedImage(imgNum: number): void {
    this.image = this.images[imgNum];
  }
}
