




















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class CustomButton extends Vue {
  @Prop({ type: Boolean, default: true }) private primary!: boolean;
  @Prop() private secondary!: boolean;
  @Prop() private warning!: boolean;
  @Prop() private danger!: boolean;
  @Prop() private loading!: boolean;
  @Prop() private disabled!: boolean;
  @Prop() private white!: boolean;

  // eslint-disable-next-line
  get cssClasses() {
    let isPrimary = this.primary && !this.secondary;
    let isSecondary = this.secondary;
    return {
      "custom-button": true,
      "custom-button--primary": isPrimary,
      "custom-button--secondary": isSecondary,
      "custom-button--warning": this.warning,
      "custom-button--danger": this.danger,
      "custom-button--disabled": this.disabled,
      "custom-button--white": this.white,
    };
  }
}
