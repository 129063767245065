













































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import BugReportModal from "@/components/modals/BugReportModal.vue";

@Component({
  components: {
    BugReportModal,
  },
  computed: mapState(["discordUser", "discordLoggedIn"]),
})
export default class Navigation extends Vue {
  private navEnabled = true;
  public showBugReportModal = false;

  public navMenuClicked(): void {
    this.navEnabled = !this.navEnabled;
  }

  public logout(): void {
    //TODO: CLEAR COOKIE ONCE HAVE ONE
    if (this.$cookies.isKey("UserGuid")) {
      this.$cookies.remove("UserGuid");
    }
    this.$store.state.discordLoggedIn = false;
  }

  public closeModal(modal: string): void {
    this.showBugReportModal = false;
    switch (modal) {
      case "showLoginErrorModal":
        this.$store.state.loginError = false;
        break;
    }
  }

  public toggleBugModal(): void {
    this.showBugReportModal = true;
  }
}
