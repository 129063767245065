var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-container relative flex h-full"},[(_vm.discordLoggedIn)?_c('div',[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Report a Bug',
        placement: 'left-center',
        offset: 10,
        delay: {
          show: 150,
          hide: 100,
        },
      }),expression:"{\n        content: 'Report a Bug',\n        placement: 'left-center',\n        offset: 10,\n        delay: {\n          show: 150,\n          hide: 100,\n        },\n      }"}],staticClass:"fixed z-50 cursor-pointer bottom-5 bugbutton",on:{"click":_vm.toggleBugModal}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"bug"}})],1)])]):_c('div',[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Login to report a bug',
        placement: 'left-center',
        offset: 10,
        delay: {
          show: 150,
          hide: 100,
        },
      }),expression:"{\n        content: 'Login to report a bug',\n        placement: 'left-center',\n        offset: 10,\n        delay: {\n          show: 150,\n          hide: 100,\n        },\n      }"}],staticClass:"fixed z-50 cursor-not-allowed bottom-5 bugbutton"},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"bug"}})],1)])]),_c('div',{staticClass:"py-4 transition duration-200 ease-in-out transform small-nav text-gray",class:!_vm.navEnabled ? '-translate-x-full absolute' : '-translate-x-0 relative'},[_c('button',{staticClass:"focus:outline-none sidebar-button",on:{"click":_vm.navMenuClicked}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1),_c('hr'),_c('router-link',{staticClass:"phlink flex items-center px-4 py-2 text-2xl font-extrabold text-gray-300 no-underline space-x-2 hover:no-underline",attrs:{"to":"/"}},[_c('img',{staticClass:"pt-2",attrs:{"src":require("../assets/images/phlogosmall.png")}})]),_c('div',{staticClass:"nav-item flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"exact":"","to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"home"}})],1)])]}}])})],1),_c('div',{staticClass:"nav-item flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/vehicles","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"car"}})],1)])]}}])})],1),_c('div',{staticClass:"nav-item flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/tracks","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"road"}})],1)])]}}])})],1),_c('div',{staticClass:"nav-item flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/servers","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"server"}})],1)])]}}])})],1),_c('div',{staticClass:"nav-item flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/credits","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"list"}})],1)])]}}])})],1),(!_vm.discordLoggedIn)?_c('div',{staticClass:"nav-item discord-login flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/discordlogin","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"flex items-center"},[_c('div',{staticClass:"px-5 icon"},[_c('span',{staticClass:"iconify",attrs:{"data-icon":"bi:discord","data-inline":"true"}})])])])]}}],null,false,3054087404)})],1):_c('div',{staticClass:"nav-item flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"#","custom":""},nativeOn:{"click":function($event){return _vm.logout.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"sign-out-alt"}})],1)])]}}])})],1)],1),_c('nav',{staticClass:"transition duration-200 ease-in-out transform nav",class:!_vm.navEnabled ? '-translate-x-0 relative' : '-translate-x-full absolute'},[_c('button',{staticClass:"focus:outline-none sidebar-button",on:{"click":_vm.navMenuClicked}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-left"}})],1),_c('hr'),_c('router-link',{staticClass:"flex items-center px-4 py-2 text-2xl font-extrabold text-gray-300 no-underline space-x-2 hover:no-underline",attrs:{"to":"/"}},[_c('img',{staticClass:"h-16",attrs:{"src":require("../assets/images/phlogo.png")}}),_c('span',[_vm._v("Project Homecoming "),_c('br'),_vm._v("Stats")])]),_c('div',{staticClass:"py-8"},[_c('div',{staticClass:"flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"exact":"","to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"home"}})],1),_vm._v("Home ")])]}}])})],1),_c('div',{staticClass:"flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/vehicles","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"car"}})],1),_vm._v("Vehicles ")])]}}])})],1),_c('div',{staticClass:"flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/tracks","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"road"}})],1),_vm._v("Tracks ")])]}}])})],1),_c('div',{staticClass:"flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/servers","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"server"}})],1),_vm._v("Servers ")])]}}])})],1),_c('div',{staticClass:"flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/credits","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"list"}})],1),_vm._v("Credits ")])]}}])})],1),(!_vm.discordLoggedIn)?_c('div',{staticClass:"flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"/discordlogin","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"flex items-center"},[_c('div',{staticClass:"px-5 icon"},[_c('span',{staticClass:"iconify",attrs:{"data-icon":"bi:discord","data-inline":"true"}})]),_vm._v(" Discord Login ")])])]}}],null,false,3833137103)})],1):_c('div',{staticClass:"flex items-center"},[_c('router-link',{staticClass:"link--io",attrs:{"to":"#","custom":""},nativeOn:{"click":function($event){return _vm.logout.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var isActive = ref.isActive;
      var navigate = ref.navigate;
return [_c('a',{staticClass:"main-nav__link",class:{ 'main-nav__link--isActive': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-5 icon"},[_c('font-awesome-icon',{attrs:{"icon":"sign-out-alt"}})],1),_vm._v("Logout ")])]}}])})],1)])],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.showBugReportModal)?_c('BugReportModal',{on:{"closeModal":_vm.closeModal}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }