<template>
  <div class="stat">
    <div class="text-xl font-semibold text-center">
      {{ value }}
    </div>
    <div class="text-center stats-heading text-md">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Stat",
  props: ["value", "title"],
};
</script>

<style lang="scss" scoped>
.stat {
  width: 125px;
}

.stats-heading {
  color: var(--color-accent);
}
</style>
