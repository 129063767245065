













































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { format } from "date-fns";

@Component({
  computed: mapState(["raceResults"]),
})
export default class Player extends Vue {
  //private raceId: string = this.$route.params.raceId;

  get raceIdParam(): string {
    return this.$store.state.route?.params?.raceId;
  }

  mounted(): void {
    if (!isNaN(parseFloat(this.raceIdParam))) {
      this.$store.dispatch("lookupRaceAction", this.raceIdParam);
    } else {
      Vue.$toast.error("Error could not find race result");
    }
  }

  convertRaceTime(racetime: number, dnf: boolean, dsq: boolean): string {
    let date = new Date(racetime);

    let MyDateString =
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2) +
      "." +
      ("00" + date.getMilliseconds()).slice(-3);

    if (date.getMinutes() == 0 && date.getSeconds() == 0) {
      if (dnf == true) {
        return "DNF";
      } else if (dsq == true) {
        return "DSQ";
      } else {
        return "";
      }
    }

    return MyDateString;
  }

  // eslint-disable-next-line
  findLaps(results: any): number {
    let highestLapCount = 0;
    for (let racer in results) {
      if (highestLapCount < results[racer].lapCount) {
        highestLapCount = results[racer].lapCount;
      }
    }
    return highestLapCount;
  }

  parseDate(datetime: string): string {
    datetime = datetime + "Z";
    let tempdate = new Date(datetime);
    let date = tempdate;
    return format(date, "yyyy-MM-dd -- HH:mm");
  }

  getTrackImage(trackID: number): string {
    let baseimgPath =
      "https://objectstorage.eu-frankfurt-1.oraclecloud.com/p/xmCpD2U-MsovkVtXACMrBT4afwbohA_nia3FdpPfmJqRQKxPEHH_VwtkhpOADxMW/n/frr1enrdwhf0/b/trackMaps/o/";
    let endimagePath = ".jpg?alt=media";
    return baseimgPath + trackID + endimagePath;
  }
}
