<template>
  <div>
    <VehiclesComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import VehiclesComponent from "@/components/Vehicles.vue";

export default {
  name: "Vehicles",
  components: {
    VehiclesComponent,
  },
};
</script>
