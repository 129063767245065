





































import { Component, Vue, Prop } from "vue-property-decorator";
import CustomButton from "@/components/shared/CustomButton.vue";
import Modal from "@/components/shared/Modal.vue";

@Component({
  components: {
    Modal,
    CustomButton,
  },
})
export default class AddPlayerNoteModal extends Vue {
  @Prop() private racerId!: number;

  public bugReport = {
    author: "",
    userId: "",
    date: "",
    message: "",
  };

  public cancel(): void {
    this.$emit("closeModal");
  }

  public submitPlayerNote(): void {
    const date = new Date(Date.now());
    date.setHours(0, 0, 0, 0);

    const formData = new FormData();

    formData.append("userId", this.$store.state.discordUser.racerGuid);
    formData.append("racerId", this.$props.racerId);
    formData.append("author", this.$store.state.discordUser.racerName);
    formData.append("date", new Date().toUTCString());
    formData.append("message", this.bugReport.message);

    //console.log(this.bugReport);
    if (this.racerId != 0) {
      this.$store.dispatch("AddPlayerNote", formData);
    } else {
      Vue.$toast.error("Error adding note.. Unknown RacerId. Tell Applez.");
    }
    this.$emit("closeModal");
  }
}
