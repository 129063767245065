









































import { Component, Vue } from "vue-property-decorator";
import CustomButton from "@/components/shared/CustomButton.vue";
import Modal from "@/components/shared/Modal.vue";
import { mapState } from "vuex";
import Backdrop from "@/components/shared/Backdrop.vue";

@Component({
  computed: mapState([
    "isLoading"
  ]),
  components: {
    Modal,
    CustomButton,
    Backdrop
  },
})
export default class BugReportModal extends Vue {

  public cancel(): void {
    this.$emit("closeModal");
  }

  public confirmModal(): void {
    this.$emit("confirmModal");
  }


}
