











































































































import { Component, Vue } from "vue-property-decorator";
import CustomButton from "@/components/shared/CustomButton.vue";
import Modal from "@/components/shared/Modal.vue";

@Component({
  components: {
    Modal,
    CustomButton,
  },
})
export default class BugReportModal extends Vue {
  public bugReport = {
    reporter: "",
    discordid: "",
    reporteddate: "",
    bugtype: "",
    car: "",
    track: "",
    summary: "",
    message: "",
    attachments: [],
  };
  $refs!: {
    file: HTMLFormElement;
  };

  public cancel(): void {
    this.$emit("closeModal");
  }

  public submitBug(): void {
    const date = new Date(Date.now());
    date.setHours(0, 0, 0, 0);

    const formData = new FormData();
    for (var i = 0; i < this.bugReport.attachments.length; i++) {
      let file = this.bugReport.attachments[i];
      formData.append("attachments[" + i + "]", file);
    }

    formData.append("discordId", this.$store.state.discordUser.discordId);
    formData.append("reporter", this.$store.state.discordUser.racerName);
    formData.append("reportedDate", new Date().toUTCString());
    formData.append("car", this.bugReport.car);
    formData.append("track", this.bugReport.track);
    formData.append("bugType", this.bugReport.bugtype);
    formData.append("summary", this.bugReport.summary);
    formData.append("message", this.bugReport.message);

    //console.log(this.bugReport);
    this.$store.dispatch("AddBug", formData);
    this.$emit("closeModal");
  }

  public attachFile(): void {
    this.bugReport.attachments = this.$refs.file.files;
  }

  public removeFiles(): void {
    this.bugReport.attachments = [];
    this.$refs.file.value = null;
  }
}
