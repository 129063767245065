<template>
  <div class="py-2 pl-2 main-wrapper">
    <div class="flex gap-4 pb-2 font-bold text-accent">
      <div
        @click="selectServer('MAIN')"
        class="px-2 py-2 transition duration-200 transform rounded cursor-pointer hover:bg-gray-600"
        v-bind:class="{ active: serverOne }"
      >
        Server 1
      </div>
      <div
        @click="selectServer('SERVER2')"
        class="px-2 py-2 transition duration-200 transform rounded cursor-pointer hover:bg-gray-600"
        v-bind:class="{ active: serverTwo }"
      >
        Server 2
      </div>
      <div
        @click="selectServer('SERVER3')"
        class="px-2 py-2 transition duration-200 transform rounded cursor-pointer hover:bg-gray-600"
        v-bind:class="{ active: serverThree }"
      >
        Server 3
      </div>
    </div>
    <hr />
    <!-- chats -->
    <div v-if="serverOne">
      <div v-for="(serverOneChat, index) in chatLogs.serverOne" :key="index">
        <ChatComponent :serverChat="serverOneChat"></ChatComponent>
      </div>
    </div>

    <div v-else-if="serverTwo">
      <div v-for="(serverTwoChat, index) in chatLogs.serverTwo" :key="index">
        <ChatComponent :serverChat="serverTwoChat"></ChatComponent>
      </div>
    </div>

    <div v-else-if="serverThree">
      <div
        v-for="(serverThreeChat, index) in chatLogs.serverThree"
        :key="index"
      >
        <ChatComponent :serverChat="serverThreeChat"></ChatComponent>
      </div>
    </div>

    <transition name="fade">
      <div
        id="pagetop"
        class="fixed bottom-0 right-0 cursor-pointer"
        v-show="scY > 300"
        @click="toTop"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#4a5568"
          stroke-width="1"
          stroke-linecap="square"
          stroke-linejoin="arcs"
        >
          <path d="M18 15l-6-6-6 6" />
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import ChatComponent from "@/components/ChatComponent.vue";
export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
      serverOne: true,
      serverTwo: false,
      serverThree: false,
    };
  },

  components: {
    ChatComponent,
  },

  mounted() {
    this.$store.dispatch("getChatLog");
    window.addEventListener("scroll", this.handleScroll);
  },

  computed: {
    ...mapState(["chatLogs"]),
  }, // END COMPUTED

  watch: {},

  methods: {
    handleScroll: function() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    selectServer: function(server) {
      switch (server) {
        case "MAIN":
          this.serverOne = true;
          this.serverTwo = false;
          this.serverThree = false;
          break;
        case "SERVER2":
          this.serverOne = false;
          this.serverTwo = true;
          this.serverThree = false;
          break;
        case "SERVER3":
          this.serverOne = false;
          this.serverTwo = false;
          this.serverThree = true;
          break;
      }
    },
  },
};
</script>

<style lang="scss">
hr {
  display: block;
  content: "";
  height: 1px;
  margin-top: 1px;
  border-style: solid;
  border-color: #cfcfcf69;
  color: #cfcfcf69;
  border-width: 0 0 0px 0;
  border-radius: 0px;
  background-image: linear-gradient(
    90deg,
    rgba(#cfcfcf69, 0.5) 50%,
    rgba(#cfcfcf69, 0) 80%
  );
}

.main-wrapper {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 20px;
  @apply bg-gray-500 bg-opacity-20 rounded-lg shadow-lg mt-3;
}

.active {
  @apply bg-gray-500 bg-opacity-30;
}

.dateMinWidth {
  min-width: 96px;
}

.nameMinWidth {
  min-width: 160px;
}
</style>
