











































import { Component, Vue } from "vue-property-decorator";
import CustomButton from "@/components/shared/CustomButton.vue";
import Modal from "@/components/shared/Modal.vue";

@Component({
  components: {
    Modal,
    CustomButton,
  },
})
export default class TestModal extends Vue {
  public announcementTitle = "";
  public selectedIcon = "bell";
  public announcementMessage = "";

  public cancel(): void {
    this.$emit("closeModal");
  }

  public postAnnouncement(): void {
    const date = new Date(Date.now());
    date.setHours(0, 0, 0, 0);

    const announcement = {
      date: date,
      author: this.$store.state.discordUser.racerName,
      userId: this.$store.state.discordUser.racerGuid,
      icon: this.selectedIcon,
      title: this.announcementTitle,
      message: this.announcementMessage,
    };
    this.$store.dispatch("AddAnnouncement", announcement);
    this.$emit("closeModal");
  }
}
