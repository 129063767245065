
















































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import CustomButton from "@/components/shared/CustomButton.vue";

@Component({
  computed: mapState([
    "raceOnePlayers",
    "raceTwoPlayers",
    "raceThreePlayers",
    "raceOneServerInfo",
    "raceTwoServerInfo",
    "raceThreeServerInfo",
  ]),
  components: {
    CustomButton,
  },
})
export default class Player extends Vue {
  mounted(): void {
    window.setInterval(() => this.fetchEventsList(), 30000);
    this.$store.dispatch("getRaceOneServerInfo");
    this.$store.dispatch("getRaceTwoServerInfo");
    this.$store.dispatch("getRaceThreeServerInfo");
    this.$store.dispatch("getRaceOnePlayers");
    this.$store.dispatch("getRaceTwoPlayers");
    this.$store.dispatch("getRaceThreePlayers");
  }

  fetchEventsList(): void {
    this.$store.dispatch("getRaceOneServerInfo");
    this.$store.dispatch("getRaceTwoServerInfo");
    this.$store.dispatch("getRaceThreeServerInfo");
    this.$store.dispatch("getRaceOnePlayers");
    this.$store.dispatch("getRaceTwoPlayers");
    this.$store.dispatch("getRaceThreePlayers");
  }

  connectToServer(server: string): void {
    switch (server) {
      case "serverOne":
        window.open("fivem://connect/race.phomecoming.com");
        break;
      case "serverTwo":
        window.open("fivem://connect/race2.phomecoming.com");
        break;
      case "serverThree":
        window.open("fivem://connect/race3.phomecoming.com");
        break;
      case "testingServer":
        window.open("fivem://connect/testing.phomecoming.com");
        break;
      case "devServer":
        window.open("fivem://connect/dev.phomecoming.com");
        break;
    }
  }
}
