<template>
  <div>
    <ServersComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import ServersComponent from "@/components/Servers.vue";

export default {
  name: "Servers",
  components: {
    ServersComponent,
  },
};
</script>
