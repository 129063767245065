<template>
  <div>
    <RaceDetailsComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import RaceDetailsComponent from "@/components/RaceDetails.vue";

export default {
  name: "RaceDetails",
  components: {
    RaceDetailsComponent,
  },
};
</script>
