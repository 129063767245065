










































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import TestModal from "@/components/modals/TestModal.vue";
import AnnouncementModal from "@/components/modals/AddAnnouncementModal.vue";
import DeleteAnnouncementModal from "@/components/modals/RemoveAnnouncementModal.vue";
import CustomButton from "@/components/shared/CustomButton.vue";
import { mapState } from "vuex";
import { format } from 'date-fns';

@Component({
  components: {
    TestModal,
    CustomButton,
    AnnouncementModal,
    DeleteAnnouncementModal
  },
  computed: mapState([
    "discordUser",
    "discordLoggedIn",
    "announcements",
    "cfxRssFeed",
    "playerStats",
    "discordAnnouncements",
    "lookupRacesResult",
    "raceOnePlayers",
    "raceTwoPlayers"
  ]),
})
export default class Home extends Vue {
  public racerToSearch = "";
  public announcementToRemoveId = 0;

  mounted(): void {
    /*** Discord Announcements ***/
    this.$store.dispatch("fetchDiscordAnnouncements");

    /*** CFX RSS FEED ***/
    this.$store.dispatch("fetchCfxRSS");

    /*** Recent Races ***/
    this.$store.dispatch("lookupRacesAction");

    /*** Announcements ***/
    this.$store.dispatch("getAllAnnouncements");

    window.setInterval(() => this.fetchEventsList(), 30000);
    this.$store.dispatch("getRaceOneServerInfo");
    this.$store.dispatch("getRaceTwoServerInfo");
    this.$store.dispatch("getRaceThreeServerInfo");
    this.$store.dispatch("getRaceOnePlayers");
    this.$store.dispatch("getRaceTwoPlayers");
    this.$store.dispatch("getRaceThreePlayers");
  }

  fetchEventsList(): void {
    this.$store.dispatch("getRaceOneServerInfo");
    this.$store.dispatch("getRaceTwoServerInfo");
    this.$store.dispatch("getRaceThreeServerInfo");
    this.$store.dispatch("getRaceOnePlayers");
    this.$store.dispatch("getRaceTwoPlayers");
    this.$store.dispatch("getRaceThreePlayers");
  }

  private showTestModal = false;
  private showAddAnnouncementModal = false;
  private showRemoveAnnouncementModal = false;

  closeModal(): void {
    this.showTestModal = false;
    this.showAddAnnouncementModal = false;
    this.showRemoveAnnouncementModal = false;
  }

  connectToServer(server: string): void {
    switch (server) {
      case "serverOne":
        window.open("fivem://connect/race.phomecoming.com");
        break;
      case "serverTwo":
        window.open("fivem://connect/race2.phomecoming.com");
        break;
      case "serverThree":
        window.open("fivem://connect/race3.phomecoming.com");
        break;
      case "testingServer":
        window.open("fivem://connect/testing.phomecoming.com");
        break;
      case "devServer":
        window.open("fivem://connect/dev.phomecoming.com");
        break;
    }
  }

  formatdate(date: any): string{
      return format(new Date(date), 'MMM. dd, yyyy HH:mm OO');
    }

  OpenModal(): void {
    this.showTestModal = true;
  }

  AddAnnouncementModal(): void {
    this.showAddAnnouncementModal = true;
  }

  RemoveAnnouncementModal(id: number): void {
    this.announcementToRemoveId = id;
    this.showRemoveAnnouncementModal = true;
  }

  lookupRacer(): void {
    this.$router.push({
      name: "Player",
      params: {
        discordId: this.racerToSearch,
      },
    });
  }

  parseDate(datetime: string): string {
    datetime = datetime + "Z";
    let tempdate = new Date(datetime);
    let date = tempdate;
    return format(date, 'yyyy-MM-dd -- HH:mm');
  }
}
