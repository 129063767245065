<template>
  <div>
    <VehicleDetailsComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import VehicleDetailsComponent from "@/components/VehicleDetails.vue";

export default {
  name: "VehicleDetails",
  components: {
    VehicleDetailsComponent,
  },
};
</script>
