import Vue from "vue";
import App from "./App.vue";
import "./assets/styles.scss";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSteamSquare } from "@fortawesome/free-brands-svg-icons";
import VueCookies from "vue-cookies";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { sync } from "vuex-router-sync";
import VTooltip from "v-tooltip";
import VueShowdownPlugin from "vue-showdown";
import Multiselect from 'vue-multiselect';

library.add(fas);
library.add(faPencilAlt);
library.add(faSteamSquare);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component('multiselect', Multiselect);

Vue.config.productionTip = false;
Vue.use(VueCookies);

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

Vue.use(VTooltip);

Vue.use(VueShowdownPlugin, {
  flavor: null,
  options: {
    emoji: false,
  },
});

sync(store, router);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
