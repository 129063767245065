

























































































































import { Component, Vue } from "vue-property-decorator";
import Navigation from "./components/Navigation.vue";
import BugNotif from "./components/BugNotification.vue";
import { mapState } from "vuex";
import Backdrop from "@/components/shared/Backdrop.vue";

@Component({
  components: {
    Navigation,
    Backdrop,
    BugNotif,
  },
  computed: mapState(["isLoading", "discordUser", "discordLoggedIn", "bugs"]),
})
export default class App extends Vue {
  private showUserMenu = false;
  mounted(): void {
    /*** User Guid / Login  ***/
    if (this.$cookies.isKey("UserGuid") && !this.$store.state.discordLoggedIn) {
      //console.log(this.$cookies.get("UserGuid"));
      this.$store.dispatch("discordLogin", this.$cookies.get("UserGuid"));
    } else {
      const uri = window.location.search.substring(1);
      const params = new URLSearchParams(uri);
      let accessCode = params.get("code");
      if (accessCode != null && !this.$store.state.discordLoggedIn) {
        this.$store.dispatch("discordLogin", accessCode);
      }
    }

    this.$store.dispatch("getAllBugs");
  }

  public logout(): void {
    //TODO: CLEAR COOKIE ONCE HAVE ONE
    if (this.$cookies.isKey("UserGuid")) {
      this.$cookies.remove("UserGuid");
    }
    this.$store.state.discordLoggedIn = false;
  }

  route(page: string, param: string): void {
    this.$router.push({ name: page, params: { discordId: param } });
  }

  routeTwo(page: string): void {
    this.$router.push({ name: page });
  }
}
