<template>
  <div>
    <PlayerClassesComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import PlayerClassesComponent from "@/components/PlayerClasses.vue";

export default {
  name: "PlayerClasses",
  components: {
    PlayerClassesComponent,
  },
};
</script>
