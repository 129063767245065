





































import { Component, Vue , Prop} from "vue-property-decorator";
import CustomButton from "@/components/shared/CustomButton.vue";
import Modal from "@/components/shared/Modal.vue";

@Component({
  components: {
    Modal,
    CustomButton,
  },
})
export default class ChatLogModal extends Vue {
  @Prop() public chatHistory!: any;

  public cancel(): void {
    this.$emit("closeModal");
  }

}
