<template>
  <div class="px-4 py-2 main-wrapper">
    <transition name="fade" mode="out-in">
      <AddClassModal v-if="showAddClassModal" @closeModal="closeModal" />
    </transition>

    <transition name="fade" mode="out-in">
      <ConfirmationModal v-if="showConfirmModal" @closeModal="closeModal" @confirmModal="confirmModal"/>
    </transition>
    
    This page is still under construction, you may need to revisit the page for this to update
    <div
      class="flex px-2 py-2 mt-4 rounded cursor-pointer w-28 hover:bg-gray-500 hover:bg-opacity-30"
      @click="AddClass"
    >
      <div class="blue--accent">
        <font-awesome-icon icon="plus" /> Add Class
      </div>
    </div>
    <div
      v-for="customClass in getRacerClasses"
      :key="customClass.id"
      class="px-3 py-3 mt-4 mb-4 bg-gray-500 rounded shadow-lg bg-opacity-20"
    >
      <div class="flex items-center justify-between">
        <div class="mb-2 text-2xl">
          {{ customClass.classname }}
        </div>
        <div
        >
          <!--<div
          class="px-2 py-2 rounded cursor-pointer blue--accent hover:bg-gray-500 hover:bg-opacity-30">
            <font-awesome-icon :icon="['fas', 'pencil-alt']" /> Edit Class
          </div>-->
          <div @click="DeleteClass(customClass.id)" class="px-2 py-2 text-red-700 rounded cursor-pointer hover:bg-gray-500 hover:bg-opacity-30">
            <font-awesome-icon :icon="['fas', 'times']" /> Delete Class
          </div>
        </div>
      </div>
      <hr />
      <div class="mt-2 italic blue--accent">
        Vehicles:
      </div>
      <div class="flex flex-wrap gap-3">
        <div
          v-for="vehicle in customClass.customclassVehicles"
          :key="vehicle.id"
          class="flex flex-col"
        >
          <div class="relative">
            <img
              v-if="checkIfImageExists(vehicle.vehiclemodelId)"
              class="w-56 rounded"
              :src="getImage(vehicle.vehiclemodelId)"
              :key="vehicle.vehiclemodelId"
            />
            <img
              v-else
              class="w-56 rounded"
              :src="require('../assets/vehicles/nocar.png')"
              :key="vehicle.vehiclemodelId"
            />
            <!--<div class="absolute top-0 left-0">
              <img
                :src="require('../assets/images/ClassCorners/class-a.png')"
              />
              <div class="absolute font-bold text-white top-1 left-2">A</div>
            </div>-->
          </div>
          <div class="text-center">{{ vehicle.vehicleName }}</div>
        </div>
      </div>
    </div>
    <Backdrop v-if="isLoading" ref="backdrop" class="backdrop">
      <span
        class="absolute top-0 bottom-0 left-0 right-0 grid custom-button__loading place-items-center"
      >
        <slot name="loading">
          <!-- <i class="text-lg fas fa-spinner fa-pulse"></i> -->
          <font-awesome-icon
            :icon="['fas', 'spinner']"
            size="2x"
            pulse
            :style="{ color: 'var(--color-accent)' }"
          />
        </slot>
      </span>
    </Backdrop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddClassModal from "@/components/modals/AddClassModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Backdrop from "@/components/shared/Backdrop.vue";
export default {
  components: {
    AddClassModal,
    ConfirmationModal,
    Backdrop
  },
  data() {
    return {
      showAddClassModal: false,
      showConfirmModal: false,
      selectedClassId: 0
    };
  },

  mounted() {
    this.$store.dispatch("getRacerClasses");
  },

  computed: {
    ...mapGetters(["getRacerClasses", "isLoading"]),
  }, // END COMPUTED

  methods: {
  closeModal(modal) {
    this.showAddClassModal = false;
    this.showConfirmModal = false;
  },
  confirmModal(modal) {
    this.showConfirmModal = false;
    var formData = new FormData();
    formData.append("userGuid", this.$store.state.discordUser.racerGuid);
    formData.append("classId", this.selectedClassId)
    this.$store.dispatch("DeleteClass", formData);
    this.$store.dispatch("getRacerClasses");
  },
    AddClass() {
      this.showAddClassModal = true;
    },

    DeleteClass(id){
        this.showConfirmModal = true;
        this.selectedClassId = id;
      },

    checkIfImageExists(vehid) {
      try {
        // eslint-disable-next-line
        let vehicleItem = {};
        vehicleItem.src = require("../assets/vehicles/" +
          vehid +
          "_" +
          1 +
          ".jpg");
        vehicleItem.id = 1 - 1;
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    getImage(vehid) {
      return require("../assets/vehicles/" + vehid + "_" + 1 + ".jpg");
    },
  },
};
</script>

<style lang="scss">
hr {
  display: block;
  content: "";
  height: 1px;
  margin-top: 1px;
  border-style: solid;
  border-color: #cfcfcf69;
  color: #cfcfcf69;
  border-width: 0 0 0px 0;
  border-radius: 0px;
  background-image: linear-gradient(
    90deg,
    rgba(#cfcfcf69, 0.5) 50%,
    rgba(#cfcfcf69, 0) 80%
  );
}

.blue--accent {
  color: var(--color-accent);
}

.wrapper {
  border: 1px solid rgba(#47b3fc, 0.5);
}

.main-wrapper {
  max-width: 1200px;
}
</style>
