



























































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import CustomButton from "@/components/shared/CustomButton.vue";

@Component({
  computed: mapState(["trackList"]),
  components: {
    CustomButton,
  },
})

// @Component({
// 	computed: {
// 		sortedTrackList: function(){
// 			return this.$store.state.trackList;
// 		}
// 	}
// })
export default class Tracks extends Vue {
  private currentSort = "";
  private currentSortDir = "desc";
  private selectedCategory = "";
  private selectedCreator = "";
  private searchFilter = "";

  mounted(): void {
    this.$store.dispatch("getAllTracks");
  }

  sort(column: string): void {
    if (column === this.currentSort) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
    }

    this.currentSort = column;
  }

  get categoryList(): string[] {
    let data = new Map();
    for (let track of this.$store.state.trackList) {
      data.set(track.category, track.category);
    }

    let unique = [...data.values()];
    unique.sort((a: string, b: string) => (a < b ? -1 : 1));
    return unique;
  }

  get creatorList(): string[] {
    let data = new Map();
    for (let track of this.$store.state.trackList) {
      data.set(track.creator, track.creator);
    }

    let unique = [...data.values()];
    unique.sort((a: string, b: string) => (a < b ? -1 : 1));
    return unique;
  }

  resetFilters(): void {
    this.selectedCategory = "";
    this.selectedCreator = "";
    this.searchFilter = "";
  }

  get filteredRows(): string[] {
    // eslint-disable-next-line
    let tracks: any = this.$store.state.trackList;

    if (this.selectedCategory != "") {
      tracks = tracks.filter(
        (row: {
          title: { toString: () => string };
          category: { toString: () => string };
          creator: { toString: () => string };
        }) => {
          const categoryName = row.category.toString().toLowerCase();
          return categoryName.includes(this.selectedCategory.toLowerCase());
        }
      );
    }

    if (this.selectedCreator != "") {
      tracks = tracks.filter(
        (row: {
          title: { toString: () => string };
          category: { toString: () => string };
          creator: { toString: () => string };
        }) => {
          const creatorName = row.creator.toString().toLowerCase();
          return creatorName.includes(this.selectedCreator.toLowerCase());
        }
      );
    }

    if (this.searchFilter != "") {
      tracks = tracks.filter(
        (row: {
          title: { toString: () => string };
          category: { toString: () => string };
          creator: { toString: () => string };
        }) => {
          const trackName = row.title.toString().toLowerCase();
          const categoryName = row.category.toString().toLowerCase();
          const creatorName = row.creator.toString().toLowerCase();
          const searchTerm = this.searchFilter.toLowerCase();

          return (
            categoryName.includes(searchTerm) ||
            creatorName.includes(searchTerm) ||
            trackName.includes(searchTerm)
          );
        }
      );
    }

    // eslint-disable-next-line
    return tracks.sort((a: any, b: any) => {
      let modifier = 1;
      if (this.currentSortDir === "desc") modifier = -1;
      if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
      if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
      return 0;
    });
  }
}
