<template>
  <div class="flex items-center gap-2 px-3 pt-1">
    <div class="w-24 text-sm text-gray-400 dateMinWidth text-opacity-60">
      {{ formatDate(serverChat.timeStamp) }}
    </div>
    <div class="flex items-center gap-2">
      <div
        class="transition duration-200 transform cursor-pointer nameMinWidth hover:scale-105"
        @click="lookupRacer(serverChat.discordId)"
      >
        <div class="text-accent">
          {{ serverChat.user }}
        </div>
      </div>
      <div class="text-white">{{ serverChat.message }}</div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
export default {
  data() {
    return {};
  },

  props: {
    serverChat: Object,
  },

  methods: {
    formatDate(date) {
      const parsedDate = new Date(date);
      //   const dtDateOnly = new Date(
      //     parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1000
      //   );
      return format(parsedDate, "LLL d, HH:mm");
    },

    lookupRacer(discord) {
      this.$router.push({
        name: "Player",
        params: {
          discordId: discord,
        },
      });
    },
  },
};
</script>

<style scoped>
.dateMinWidth {
  min-width: 96px;
}

.nameMinWidth {
  min-width: 160px;
}
</style>
