import { render, staticRenderFns } from "./Servers.vue?vue&type=template&id=1b1c8836&scoped=true&"
import script from "./Servers.vue?vue&type=script&lang=ts&"
export * from "./Servers.vue?vue&type=script&lang=ts&"
import style0 from "./Servers.vue?vue&type=style&index=0&id=1b1c8836&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b1c8836",
  null
  
)

export default component.exports