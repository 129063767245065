<template>
  <div>
    <router-link to="/bugs" style="text-decoration: none; color: inherit;">
      <div
        v-tooltip="{
          content: 'Bug Reports',
          placement: 'left-center',
          offset: 10,
          delay: {
            show: 150,
            hide: 100,
          },
        }"
        class="h-full px-2 py-2 bg-gray-700 rounded-full shadow-md cursor-pointer"
      >
        <font-awesome-icon icon="bug" class="mr-1" />
        <span class="font-bold">{{ number }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["number"],
};
</script>
