
















import { Component, Vue } from "vue-property-decorator";
import CustomButton from "@/components/shared/CustomButton.vue";
import Modal from "@/components/shared/Modal.vue";

@Component({
  components: {
    Modal,
    CustomButton,
  },
})
export default class TestModal extends Vue {
  public cancel(): void {
    this.$emit("closeModal");
  }
}
