<template>
  <div>
    <RankInfoLanding />
  </div>
</template>

<script>
// @ is an alias to /src
import RankInfoLanding from "@/components/RankInfoPage.vue";

export default {
  name: "RankInfo",
  components: {
    RankInfoLanding,
  },
};
</script>
