import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Player from "../views/Player.vue";
import Servers from "../views/Servers.vue";
import RaceDetails from "../views/RaceDetails.vue";
import Credits from "../views/Credits.vue";
import Vehicles from "../views/Vehicles.vue";
import Tracks from "../views/Tracks.vue";
import TrackDetails from "../views/TrackDetails.vue";
import VehicleDetails from "../views/VehicleDetails.vue";
import Bugs from "../views/Bugs.vue";
import ChatLog from "../views/ChatLog.vue";
import CustomClasses from "../views/PlayerClasses.vue";
import Settings from "../views/Settings.vue";
import RankInfo from "../views/RankInfo.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/player/:discordId",
    name: "Player",
    component: Player,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/racedetails/:raceId",
    name: "RaceDetails",
    component: RaceDetails,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/servers",
    name: "Servers",
    component: Servers,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/vehicles",
    name: "Vehicles",
    component: Vehicles,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/tracks",
    name: "Tracks",
    component: Tracks,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/trackdetails/:trackId",
    name: "TrackDetails",
    component: TrackDetails,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/vehicledetails/:vehicleId",
    name: "VehicleDetails",
    component: VehicleDetails,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/credits",
    name: "Credits",
    component: Credits,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/bugs",
    name: "Bugs",
    component: Bugs,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customclasses",
    name: "CustomClasses",
    component: CustomClasses,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/discordlogin",
    beforeEnter() {
      location.href =
        "https://discord.com/api/oauth2/authorize?client_id=728451173660491797&redirect_uri=https%3A%2F%2Fstats.project-homecoming.com&response_type=code&scope=identify";
    },
  },
  {
    path: "/chatlog",
    name: "ChatLog",
    component: ChatLog,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/rankinfo",
    name: "RankInfo",
    component: RankInfo,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  routes,
});

function isAuthenticated(): boolean {
  if (store.state.discordLoggedIn && store.state.discordUser.isWebAdmin)
    return true;
  else return false;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated()) {
      next();
    } else {
      router.push({ path: "/" });
    }
  } else {
    next();
  }
});

export default router;
