var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":"/bugs"}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Bug Reports',
        placement: 'left-center',
        offset: 10,
        delay: {
          show: 150,
          hide: 100,
        },
      }),expression:"{\n        content: 'Bug Reports',\n        placement: 'left-center',\n        offset: 10,\n        delay: {\n          show: 150,\n          hide: 100,\n        },\n      }"}],staticClass:"h-full px-2 py-2 bg-gray-700 rounded-full shadow-md cursor-pointer"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"bug"}}),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.number))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }