<template>
  <div>
    <TrackDetailsComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import TrackDetailsComponent from "@/components/TrackDetails.vue";

export default {
  name: "TrackDetails",
  components: {
    TrackDetailsComponent,
  },
};
</script>
