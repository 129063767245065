<template>
  <div class="main-wrapper p-10">
    <div class="relative flex">
      <div class="px-2 py-2 mb-5 text-3xl italic">Bug Reports</div>
      <div class="absolute mt-12 ml-3" v-if="bugs.length === 0">
        No bugs!
      </div>
      <div v-else>
        <div class="inline-block px-5">
          <div class="guy">
            (
            <div class="arm">╯</div>
            °□°）
            <div class="arm">╯</div>
          </div>
          <div id="table">┻━┻</div>
          <div class="guy"></div>
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in bugs"
      :key="index"
      class="px-3 py-3 mb-8 bg-gray-600 rounded shadow-lg bg-opacity-30 wrapper"
    >
      <!-- header -->
      <div class="flex justify-between">
        <div>
          <div class="text-xl">{{ item.summary }}</div>
          <div>
            Type: <span class="blue--accent">{{ item.bugType }}</span>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="self-end text-xl italic blue--accent">
            {{ item.reporter }}
          </div>
          <div class="flex items-center">
            <span class="iconify" data-icon="bi:discord" data-inline="true" />
            <div class="ml-2">{{ item.discordId }}</div>
          </div>
          <div class="flex self-end italic">
            {{ item.reportedDate }}
          </div>
        </div>
      </div>
      <hr />
      <!-- Body -->
      <div class="mt-5">
        <div class="pb-5">
          {{ item.message }}
        </div>
        <div v-if="item.attachments.length > 0">
          <hr />
          <div class="mt-5 italic">
            {{ item.attachments.length }} Attachment(s) Uploaded
          </div>
          <div v-for="(attachment, index) in item.attachments" :key="index">
            <a href="#">{{ attachment }} </a>
          </div>
        </div>
      </div>
      <div class="flex justify-end gap-4">
        <div @click="deleteBugReport(item.id)" class="cursor-pointer">
          <font-awesome-icon icon="times" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("getAllBugs");
  },

  computed: {
    ...mapState(["bugs"]),
  }, // END COMPUTED

  methods: {
    deleteBugReport(id) {
      this.$store.dispatch("deleteBug", id);
    },
  },
};
</script>

<style lang="scss">
hr {
  display: block;
  content: "";
  height: 1px;
  margin-top: 1px;
  border-style: solid;
  border-color: #cfcfcf69;
  color: #cfcfcf69;
  border-width: 0 0 0px 0;
  border-radius: 0px;
  background-image: linear-gradient(
    90deg,
    rgba(#cfcfcf69, 0.5) 50%,
    rgba(#cfcfcf69, 0) 80%
  );
}

.blue--accent {
  color: var(--color-accent);
}

.wrapper {
  border: 1px solid rgba(#47b3fc, 0.5);
}

.main-wrapper {
  max-width: 1200px;
}

.guy {
  font-family: Helvetica, Arial, Verdana, sans-serif;
  font-weight: bold;
  letter-spacing: -1px;
  font-size: 30px;
  display: inline-block;
}

#table {
  font-family: Helvetica, Arial, Verdana, sans-serif;
  font-weight: bold;
  letter-spacing: -1px;
  font-size: 30px;
  animation-timing-function: linear;
  animation: table-flip 2s infinite;
  position: relative;
  display: inline-block;
}

.arm {
  animation-timing-function: linear;
  animation: arm-rotate 2s infinite;
  position: relative;
  display: inline-block;
}

@keyframes table-flip {
  0% {
    left: 0px;
    top: 0em;
    transform: rotate(-180deg);
  }
  24% {
    top: -2em;
  }
  60% {
    left: 2.5em;
    top: 0em;
    transform: rotate(0deg);
  }
  80% {
    left: 2.5em;
    top: 0em;
    transform: rotate(0deg);
  }
  100% {
    left: 0px;
    top: 0em;
    transform: rotate(-180deg);
  }
}

@keyframes arm-rotate {
  0% {
    top: 0.2em;
    transform: rotate(90deg);
  }
  20% {
    top: 0em;
    transform: rotate(0deg);
  }
  50% {
    top: 0em;
    transform: rotate(0deg);
  }
  100% {
    top: 0.2em;
    transform: rotate(90deg);
  }
}
</style>
