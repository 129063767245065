





























































import { Component, Vue } from "vue-property-decorator";
import CustomButton from "@/components/shared/CustomButton.vue";
import Modal from "@/components/shared/Modal.vue";
import { mapState } from "vuex";
import { toastOptions } from "@/components/services/ToastOptions";
import Backdrop from "@/components/shared/Backdrop.vue";

@Component({
  computed: mapState([
    "vehicleList",
    "discordUser",
    "isLoading"
  ]),
  components: {
    Modal,
    CustomButton,
    Backdrop
  },
})
export default class BugReportModal extends Vue {
  mounted(): void {
    this.$store.dispatch("getAllVehicles");
  }
  public customClass = {
    className: "",
  };
  $refs!: {
    file: HTMLFormElement;
  };

  public cancel(): void {
    this.$emit("closeModal");
  }

  public addCustomClass(): void {
    if (this.customClass.className === "")
    {
      Vue.$toast.error("Please enter a name for your class", toastOptions);
      console.log(this.$store.state.discordUser);
      return;
    }

    const formData = new FormData();
    formData.append("userGuid", this.$store.state.discordUser.racerGuid);
    formData.append("className", this.customClass.className)
    
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    for (var i = 0; i < this.customClass.vehicles.length; i++) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      let veh = this.customClass.vehicles[i].vehicleId;
      formData.append("customClassVehicles[" + i + "].vehicleModelId", veh);
    }

    this.$store.dispatch("AddCustomClass", formData);
    this.$emit("closeModal");
  }

}
