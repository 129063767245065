







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import CustomButton from "@/components/shared/CustomButton.vue";
import Stat from "@/components/shared/Stat.vue";
import RatingChart from "@/components/shared/RatingChart.vue";
import AddPlayerNoteModal from "@/components/modals/AddPlayerNoteModal.vue";
import ChatLogModal from "@/components/modals/ChatLogModal.vue";
import { format } from "date-fns";

@Component({
  computed: mapState([
    "playerStats",
    "discordLoggedIn",
    "discordUser",
    "specificRacerRating",
  ]),
  components: {
    CustomButton,
    Stat,
    RatingChart,
    AddPlayerNoteModal,
    ChatLogModal,
  },
})
export default class Player extends Vue {
  public racerToSearch = "";
  public showPlayerNoteModal = false;
  public showChatModal = false;
  public userIdToAddNoteTo = 0;
  public chatHistoryToShow = [];
  public selectedSeason = 1;
  public seasons = [
    { id: 0, name: "Overall/Season 1" },
    { id: 1, name: "Season 2" },
  ];
  //private discordId: string = this.$route.params.discordId;
  mounted(): void {
    let userId = "";
    let payload = "";
    if (this.$cookies.isKey("UserGuid")) {
      userId = this.$cookies.get("UserGuid");
    }
    if (!isNaN(parseFloat(this.discordId))) {
      if (userId != "") {
        payload = this.discordId + "?userGuid=" + userId;
      } else {
        payload = this.discordId;
      }
      this.$store.dispatch("getPlayerStatsByIdAction", payload);
    } else if (this.discordId && this.discordId != undefined) {
      if (userId != "") {
        payload = this.discordId + "&userGuid=" + userId;
      } else {
        payload = this.discordId;
      }
      this.$store.dispatch("getPlayerStatsByNameAction", payload);
    }
  }

  public racerStats(stats: [any]): any {
    const stat = stats.find((obj) => { return obj.seasonId === this.selectedSeason + 1})
    return stat; 
  }

  public racerRatingSeason(seasons: [any]): any {
    const season = seasons.find((obj) => { return obj.season === this.selectedSeason + 1})
    return season; 
  }

  public playerRaceHistory(recentRaces: any, ratingHistory: any): any {
    let index = recentRaces.length - 1;
    if (ratingHistory == null || ratingHistory.length == 0) {
      return recentRaces;
    }
    for (let i = 0; i < recentRaces.length - 1; i++) {
      //console.log("Rating History Date: " + ratingHistory[i].ratingDate + " | Recent Race Date: " + recentRaces[i].raceId.raceDateTime)
      if (index > ratingHistory.length - 1) {
        index = ratingHistory.length - 1;
      }
      if (
        recentRaces[i].raceTime == null ||
        new Date(recentRaces[i].raceTime + "Z").getFullYear() < 200
      ) {
        if (recentRaces[i].raceId.id != ratingHistory[index].raceId) {
          continue;
        }
      }
      if (index == 0) {
        return recentRaces;
      }
      if (
        ratingHistory[index - 1].visualRating <
        ratingHistory[index].visualRating
      ) {
        // Gained rating
        recentRaces[i].ratingGained = true;
      } else {
        recentRaces[i].ratingGained = false;
      }
      let mmrchange =
        ratingHistory[index].visualRating -
        ratingHistory[index - 1].visualRating;
      mmrchange = Math.round((mmrchange + Number.EPSILON) * 1000) / 1000;
      recentRaces[i].ratingChange = mmrchange;
      index--;
    }
    return recentRaces;
  }

  public closeModal(modal: string): void {
    this.showPlayerNoteModal = false;
    this.showChatModal = false;
    switch (modal) {
      case "showLoginErrorModal":
        this.$store.state.loginError = false;
        break;
    }
  }

  public openChatModal(chat: any): void {
    this.chatHistoryToShow = chat;
    this.showChatModal = true;
  }

  public togglePlayerNoteModal(user: number): void {
    this.userIdToAddNoteTo = user;
    this.showPlayerNoteModal = true;
  }

  public removePlayerNote(id: any): void {
    if (this.$store.state.discordUser.racerGuid != 0) {
      this.$store.dispatch("DeletePlayerNote", id);
    } else {
      Vue.$toast.error("Error deleting note.. Unknown RacerId. Tell Applez.");
    }
  }

  chartData(ratingHistory: any): any {
    let data = {};
    let dates = this.ratingDates(ratingHistory);
    let parsedDates = [];
    for (let i = 0; i < dates.length; i++) {
      parsedDates.push(this.parseDateTwo(dates[i] + "Z"));
    }
    data = {
      labels: parsedDates,
      datasets: [
        {
          label: "Rating History",
          data: this.ratings(ratingHistory),
          backgroundColor: "transparent",
          borderColor: "#47b3fc",
          pointBackgroundColor: "#ffffff",
        },
      ],
    };

    return data;
  }

  convertDecToHex(str: any): string {
    // .toString(16) only works up to 2^53
    if (str != null) {
      var dec = str.toString().split(""),
        sum = [],
        hex = [],
        i,
        s;
      while (dec.length) {
        s = 1 * dec.shift();
        for (i = 0; s || i < sum.length; i++) {
          s += (sum[i] || 0) * 10;
          sum[i] = s % 16;
          s = (s - sum[i]) / 16;
        }
      }
      while (sum.length) {
        let num = sum.pop();
        if (num !== undefined) hex.push(num.toString(16));
      }
      return hex.join("");
    }
    return "NOT FOUND";
  }

  copyText(text: string) {
    navigator.clipboard.writeText(text);
  }

  ratingDates(dates: any) {
    return dates.map(function(date: any) {
      return date["ratingDate"];
    });
  }

  ratings(ratings: any) {
    return ratings.map(function(rating: any) {
      return rating["visualRating"];
    });
  }

  get chartStyle() {
    return {
      height: "300px",
      position: "relative",
    };
  }

  get discordId(): string {
    return this.$store.state.route?.params?.discordId;
  }

  formatRating(rating: number) {
    if (rating != undefined) {
      return rating.toFixed(2);
    }
    return 0;
  }

  getPercentage(first: number, second: number): string {
    let percentage = Math.round((first / second) * 100);
    if (isNaN(percentage)) {
      percentage = 0;
    }
    return percentage + "%";
  }

  // eslint-disable-next-line
  getTopTen(globalStats: any): string {
    let topten = 0;
    topten += globalStats.oneCount;
    topten += globalStats.twoCount;
    topten += globalStats.threeCount;
    topten += globalStats.fourCount;
    topten += globalStats.fiveCount;
    topten += globalStats.sixthCount;
    topten += globalStats.seventhCount;
    topten += globalStats.eighthCount;
    topten += globalStats.nineCount;
    topten += globalStats.tenCount;

    let percentage = Math.round((topten / globalStats.totalRaces) * 100);
    if (isNaN(percentage)) {
      return topten + " (" + percentage + "%)";
    }

    return topten + " (" + percentage + "%)";
  }

  calculateRaceTime(unparsedTime: number): string {
    // eslint-disable-next-line
    var date: any = new Date();
    date.setFullYear(1, 0, 1);
    date.setHours(0, 0, 0, 0);
    // eslint-disable-next-line
    var newDate: any = new Date(unparsedTime);
    var parsedDate = newDate - date;
    var hours = (parsedDate / (1000 * 60 * 60)).toFixed(1);
    return hours;
  }

  convertRaceTime(racetime: number): string {
    let date = new Date(racetime);

    let MyDateString =
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2) +
      "." +
      ("00" + date.getMilliseconds()).slice(-3);

    return MyDateString;
  }

  parseDate(datetime: string): string {
    datetime = datetime + "Z";
    let tempdate = new Date(datetime);
    let date = tempdate;
    return format(date, "yyyy-MM-dd -- HH:mm");
  }

  parseDateTwo(datetime: string): string {
    let tempdate = new Date(datetime);
    let date = tempdate;
    return format(date, "yyyy-MM-dd -- HH:mm");
  }

  lookupRacer(): void {
    let userId = "";
    let payload = "";
    if (this.$cookies.isKey("UserGuid")) {
      userId = this.$cookies.get("UserGuid");
    }
    if (!isNaN(+this.racerToSearch)) {
      if (userId != "") {
        payload = this.racerToSearch + "?userGuid=" + userId;
      } else {
        payload = this.racerToSearch;
      }
      this.$store.dispatch("getPlayerStatsByIdAction", payload);
    } else if (this.discordId && this.discordId != undefined) {
      if (userId != "") {
        payload = this.racerToSearch + "&userGuid=" + userId;
      } else {
        payload = this.racerToSearch;
      }
      this.$store.dispatch("getPlayerStatsByNameAction", payload);
    }
    //console.log(this.racerToSearch);
  }

  rankImagePath(rankName: string): string {
    switch (rankName) {
      case "Sunday Driver": {
        return "rank-bronze-2.png";
      }
      case "Silver": {
        return "rank-silver-1.png";
      }
      case "Gold": {
        return "rank-gold-0.png";
      }
      case "Platinum": {
        return "rank-plat-0.png";
      }
      case "Diamond": {
        return "rank-diamond-0.png";
      }
    }

    return "rank-bronze-2.png";
  }
}
