import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { toastOptions } from "@/components/services/ToastOptions";

const discordApibase = "https://api.project-homecoming.com/api/Discord/api/v1/";
//const statsApibase = "http://localhost:57073/api/v1/";
const apibase = "https://api.project-homecoming.com/api/v1/";
//const apibase = "http://localhost:57073/api/v1/";
const phHeaders = {
  headers: {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJBcHBsZXoiLCJhdWQiOiJQSEMifQ.8wYpyAmi6hkoLExqg_2jYZcRwCps6HTwinkIWjxMQdg",
  },
};

Vue.use(Vuex, axios);

export default new Vuex.Store({
  state: {
    isLoading: false,
    // eslint-disable-next-line
    discordUser: {} as any,
    discordLoggedIn: false,
    loginError: false,
    playerStats: {} as any,
    raceOnePlayers: [],
    raceOneServerInfo: [],
    raceTwoPlayers: [],
    raceTwoServerInfo: [],
    raceThreePlayers: [],
    raceThreeServerInfo: [],
    discordAnnouncements: [],
    racerRating: [],
    specificRacerRating: [],
    announcements: [],
    // eslint-disable-next-line
    cfxRssFeed: [] as any,
    raceResults: [],
    lookupRacesResult: [],
    vehicleList: [],
    trackList: [],
    bugs: [],
    racerClasses: [],
    chatLogs: {},
  },

  getters: {
    getRacerClasses: (state) => {
      return state.racerClasses;
    }
  },

  mutations: {
    /****  Server Info ***/
    serverInfo: (state, payload) => {
      switch (payload.serverNumber) {
        case 1:
          state.raceOneServerInfo = payload;
          break;
        case 2:
          state.raceTwoServerInfo = payload;
          break;
        case 3:
          state.raceThreeServerInfo = payload;
          break;
      }
    },

    raceOnePlayers: (state, payload) => {
      const players = Object.entries(payload);
      for (let i = 0; i < players.length; i++) {
        switch (payload[i]["role"]) {
          case "Staff":
            payload[i].order = 0;
            break;
          case "Director":
            payload[i].order = 1;
            break;
          case "Racer":
            payload[i].order = 2;
            break;
          case "Regular+":
            payload[i].order = 3;
            break;
          case "Regular":
            payload[i].order = 4;
            break;
          case "Rookie":
            payload[i].order = 5;
            break;
          case "Registered":
            payload[i].order = 6;
            break;
          case "Troll":
            payload[i].order = 7;
            break;
          case "Guest":
            payload[i].order = 8;
            break;
          default:
            payload[i].order = 9;
            break;
        }
      }

      // eslint-disable-next-line
      state.raceOnePlayers = payload.sort((a: any, b: any) =>
        a.order < b.order ? -1 : 1
      );
    },

    raceTwoPlayers: (state, payload) => {
      const players = Object.entries(payload);
      for (let i = 0; i < players.length; i++) {
        switch (payload[i]["role"]) {
          case "Staff":
            payload[i].order = 0;
            break;
          case "Director":
            payload[i].order = 1;
            break;
          case "Racer":
            payload[i].order = 2;
            break;
          case "Regular+":
            payload[i].order = 3;
            break;
          case "Regular":
            payload[i].order = 4;
            break;
          case "Rookie":
            payload[i].order = 5;
            break;
          case "Registered":
            payload[i].order = 6;
            break;
          case "Troll":
            payload[i].order = 7;
            break;
          case "Guest":
            payload[i].order = 8;
            break;
          default:
            payload[i].order = 9;
            break;
        }
      }

      // eslint-disable-next-line
      state.raceTwoPlayers = payload.sort((a: any, b: any) =>
        a.order < b.order ? -1 : 1
      );
    },

    raceThreePlayers: (state, payload) => {
      const players = Object.entries(payload);
      for (let i = 0; i < players.length; i++) {
        switch (payload[i]["role"]) {
          case "Staff":
            payload[i].order = 0;
            break;
          case "Director":
            payload[i].order = 1;
            break;
          case "Racer":
            payload[i].order = 2;
            break;
          case "Regular+":
            payload[i].order = 3;
            break;
          case "Regular":
            payload[i].order = 4;
            break;
          case "Rookie":
            payload[i].order = 5;
            break;
          case "Registered":
            payload[i].order = 6;
            break;
          case "Troll":
            payload[i].order = 7;
            break;
          case "Guest":
            payload[i].order = 8;
            break;
          default:
            payload[i].order = 9;
            break;
        }
      }

      // eslint-disable-next-line
      state.raceThreePlayers = payload.sort((a: any, b: any) =>
        a.order < b.order ? -1 : 1
      );
    },

    /****  User Login ***/
    discordLogin: (state, payload) => {
      state.discordUser = payload;
      state.discordLoggedIn = true;
      state.isLoading = false;
      //console.log(payload);
      Vue.$cookies.set("UserGuid", payload.racerGuid, "30d");
    },

    /****  User Stats ***/
    getPlayerstatsById: (state, payload) => {
      if (payload.ratingHistory != null || payload.ratingHistory != undefined) {
        // eslint-disable-next-line
        state.playerStats.ratingHistory = payload.ratingHistory.sort(
          (a: any, b: any) => (a.ratingDate < b.ratingDate ? -1 : 1)
        );
      }

      state.playerStats = payload;
      if (state.playerStats.length > 0) {
        for (let i = 0; i < state.playerStats.length; i++) {
          if (
            state.playerStats[i].ratingHistory != null ||
            state.playerStats[i].ratingHistory != undefined
          ) {
            state.playerStats[i].ratingHistory.sort((a: any, b: any) =>
              a.ratingDate < b.ratingDate ? -1 : 1
            );
          }
        }
      }
      state.isLoading = false;
    },

    /****  CFX RSS Feed ***/
    setCfxRSS: (state, payload) => {
      const parsedFeed = new window.DOMParser().parseFromString(
        payload,
        "text/xml"
      );
      const items = parsedFeed.querySelectorAll("item");
      items.forEach((el) => {
        const cfxItemToAdd = {
          title: "",
          description: "",
          pubDate: "",
        };

        cfxItemToAdd.title = String(el.querySelector("title")?.innerHTML);

        const e = document.createElement("div");
        e.innerHTML = String(el.querySelector("description")?.innerHTML);

        cfxItemToAdd.description = String(e.childNodes[0].nodeValue);
        cfxItemToAdd.pubDate = String(el.querySelector("pubDate")?.innerHTML);
        //console.log(cfxItemToAdd);
        state.cfxRssFeed.push(cfxItemToAdd);
      });
    },

    setDiscordAnnouncements: (state, payload) => {
      state.discordAnnouncements = payload;
    },

    lookupRace: (state, payload) => {
      // eslint-disable-next-line
      let sortedResults = payload.results.sort((a: any, b: any) =>
        a.position < b.position ? -1 : 1
      );
      payload.results = sortedResults;
      state.raceResults = payload;
      //state.raceResults = payload;
      state.isLoading = false;
    },

    lookupRaces: (state, payload) => {
      state.lookupRacesResult = payload;
      state.isLoading = false;
    },

    allVehicles: (state, payload) => {
      // eslint-disable-next-line
      let sortedResults = payload.sort((a: any, b: any) =>
        a.vehicleName < b.vehicleName ? -1 : 1
      );
      state.vehicleList = sortedResults;
      state.isLoading = false;
    },

    allTracks: (state, payload) => {
      // eslint-disable-next-line
      let sortedResults = payload.sort((a: any, b: any) =>
        a.title < b.title ? -1 : 1
      );
      state.trackList = sortedResults;
      state.isLoading = false;
    },

    setAnnouncements: (state, payload) => {
      state.announcements = payload;
      state.isLoading = false;
    },

    setBugs: (state, payload) => {
      state.bugs = payload;
      state.isLoading = false;
    },

    setRacerClasses: (state, payload) => {
      state.racerClasses = payload;
      state.isLoading = false;
    },

    getChatLog: (state, payload) => {
      state.chatLogs = payload;
      state.isLoading = false;
    },
  },
  actions: {
    /*** Get All Vehicles */
    getAllVehicles: (context) => {
      context.state.isLoading = true;
      axios
        .get(apibase + "GetAllCars", phHeaders)
        .then((response) => {
          context.commit("allVehicles", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error fetching vehicles " + error, toastOptions);
        });
    },

    /*** Get All Tracks */
    getAllTracks: (context) => {
      context.state.isLoading = true;
      axios
        .get(
          "https://api.project-homecoming.com/api/ActualCharlie/api/v1/GetAllTracks",
          phHeaders
        )
        .then((response) => {
          context.commit("allTracks", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error fetching tracks " + error, toastOptions);
        });
    },

    /*** Lookup Races */
    lookupRacesAction: (context) => {
      context.state.isLoading = true;
      axios
        .get(apibase + "GetRecentRaces", phHeaders)
        .then((response) => {
          context.commit("lookupRaces", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching recent races " + error,
            toastOptions
          );
        });
    },

    /****  Server Info ***/
    getRaceOneServerInfo: (context) => {
      axios
        .get(apibase + "GetMainServerTrack", phHeaders)
        .then((response) => {
          response.data.serverNumber = 1;
          context.commit("serverInfo", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching race 1 server info " + error,
            toastOptions
          );
        });
    },

    getRaceTwoServerInfo: (context) => {
      axios
        .get(apibase + "GetServerTwoTrack", phHeaders)
        .then((response) => {
          response.data.serverNumber = 2;
          context.commit("serverInfo", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching race 2 server info " + error,
            toastOptions
          );
        });
    },

    getRaceThreeServerInfo: (context) => {
      axios
        .get(apibase + "GetServerThreeTrack", phHeaders)
        .then((response) => {
          response.data.serverNumber = 3;
          context.commit("serverInfo", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching race 3 server info " + error,
            toastOptions
          );
        });
    },

    getRaceOnePlayers: (context) => {
      axios
        .get(apibase + "GetAllRaceOnePlayers", phHeaders)
        .then((response) => {
          context.commit("raceOnePlayers", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching race 1 players " + error,
            toastOptions
          );
        });
    },

    getRaceTwoPlayers: (context) => {
      axios
        .get(apibase + "GetAllRaceTwoPlayers", phHeaders)
        .then((response) => {
          context.commit("raceTwoPlayers", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching race 2 players " + error,
            toastOptions
          );
        });
    },

    getRaceThreePlayers: (context) => {
      axios
        .get(apibase + "GetAllRaceThreePlayers", phHeaders)
        .then((response) => {
          context.commit("raceThreePlayers", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching race 3 players " + error,
            toastOptions
          );
        });
    },

    /****  User Login ***/
    discordLogin: (context, discordTokenCookie) => {
      context.state.isLoading = true;
      axios
        .get(
          discordApibase + "DiscordLogin?authToken=" + discordTokenCookie,
          phHeaders
        )
        .then((response) => {
          context.commit("discordLogin", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error logging in with discord " + error,
            toastOptions
          );
          Vue.$cookies.remove("UserGuid");
          context.state.loginError = true;
          context.state.isLoading = false;
        });
    },

    /****  Chat Log ***/
    getChatLog: (context) => {
      context.state.isLoading = true;
      axios
        .get(apibase + "GetChatLog/", phHeaders)
        .then((response) => {
          context.commit("getChatLog", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error fetching chat log " + error, toastOptions);
        });
    },

    /****  User Stats ***/
    getPlayerStatsByIdAction: (context, payload) => {
      context.state.isLoading = true;
      axios
        .get(apibase + "GetRacerById/" + payload, phHeaders)
        .then((response) => {
          context.commit("getPlayerstatsById", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error fetching racer by Id " + error, toastOptions);
        });
    },

    getPlayerStatsByNameAction: (context, payload) => {
      context.state.isLoading = true;
      axios
        .get(apibase + "GetRacerByName?name=" + payload, phHeaders)
        .then((response) => {
          //console.log(response.data);
          context.commit("getPlayerstatsById", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching racer by name " + error,
            toastOptions
          );
        });
    },

    /****  CFX RSS Feed ***/
    fetchCfxRSS: (context) => {
      axios
        .get("https://status.cfx.re/history.rss")
        .then((response) => {
          context.commit("setCfxRSS", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching FiveM Issues " + error,
            toastOptions
          );
        });
    },

    /****  PH Announcements ***/
    fetchDiscordAnnouncements: (context) => {
      axios
        .get(discordApibase + "DiscordAnnouncements", phHeaders)
        .then((response) => {
          context.commit("setDiscordAnnouncements", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching discord announcements " + error,
            toastOptions
          );
        });
    },

    /**** Admin Functions ***/
    AddPlayerNote: (context, payload) => {
      axios
        .post(apibase + "AddPlayerNote", payload, phHeaders)
        .then(() => {
          Vue.$toast.success("Added Player Note!", toastOptions);
        })
        .catch((error) => {
          Vue.$toast.error("Error adding note " + error, toastOptions);
        });
    },

    DeletePlayerNote: (context, payload) => {
      //console.log(payload);
      axios
        .delete(
          apibase +
            "DeletePlayerNote?noteId=" +
            payload +
            "&userId=" +
            context.state.discordUser.discordId,
          phHeaders
        )
        .then(() => {
          Vue.$toast.success("Removed note!", toastOptions);
        })
        .catch((error) => {
          Vue.$toast.error("Error deleting note " + error, toastOptions);
        });
    },

    /****  Settings ***/
    UpdateUserSettings: (context, payload) => {
      axios
        .put(apibase + "UpdateRankingVisibility", payload, phHeaders)
        .then(() => {
          Vue.$toast.success("Settings Updated!", toastOptions);
        })
        .catch((error) => {
          Vue.$toast.error("Error Updating Settings " + error, toastOptions);
        });
    },

    /****  Custom Classes ***/
    AddCustomClass: (context, payload) => {
      axios
        .post(apibase + "AddCustomClass", payload, phHeaders)
        .then(() => {
          Vue.$toast.success("Class Added!", toastOptions);
          context.dispatch('getRacerClasses');
        })
        .catch((error) => {
          Vue.$toast.error("Error adding class " + error, toastOptions);
        });
    },

    DeleteClass: (context, payload) => {
      //console.log(payload);
      const config = {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJBcHBsZXoiLCJhdWQiOiJQSEMifQ.8wYpyAmi6hkoLExqg_2jYZcRwCps6HTwinkIWjxMQdg",
        },
        data: payload
      }
      console.log(config);
      axios
        .delete( apibase + "DeleteCustomClass", config)
          .then(() => {
            Vue.$toast.success("Removed class!", toastOptions);
            context.dispatch('getRacerClasses');
          })
        .catch((error) => {
          Vue.$toast.error("Error deleting class " + error, toastOptions);
        });
    },

    /****  Bug Reports ***/
    AddBug: (context, payload) => {
      axios
        .post(apibase + "AddBug", payload, phHeaders)
        .then(() => {
          Vue.$toast.success("Bug Submitted!", toastOptions);
          context.dispatch('getAllBugs');
        })
        .catch((error) => {
          Vue.$toast.error("Error submitting bug " + error, toastOptions);
        });
    },

    deleteBug: (context, payload) => {
      axios
        .delete(apibase + "DeleteBug/" + payload, phHeaders)
        .then(() => {
          context.dispatch("getAllBugs");
          Vue.$toast.success("Removed bug!", toastOptions);
        })
        .catch((error) => {
          Vue.$toast.error("Error deleting bug " + error, toastOptions);
        });
    },

    getAllBugs: (context) => {
      axios
        .get(apibase + "GetAllBugs", phHeaders)
        .then((response) => {
          context.commit("setBugs", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error getting bugs " + error, toastOptions);
        });
    },

    /****  Announcements ***/
    getAllAnnouncements: (context) => {
      axios
        .get(apibase + "GetAnnouncements", phHeaders)
        .then((response) => {
          context.commit("setAnnouncements", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error fetching announcements " + error,
            toastOptions
          );
        });
    },

    AddAnnouncement: (context, payload) => {
      axios
        .post(apibase + "AddAnnouncement", payload, phHeaders)
        .then(() => {
          context.dispatch("getAllAnnouncements");
          Vue.$toast.success("Added Announcement!", toastOptions);
        })
        .catch((error) => {
          Vue.$toast.error("Error posting announcement " + error, toastOptions);
        });
    },

    deleteAnnouncement: (context, payload) => {
      axios
        .delete(apibase + "DeleteAnnouncement/" + payload, phHeaders)
        .then(() => {
          context.dispatch("getAllAnnouncements");
          Vue.$toast.success("Removed Announcement!", toastOptions);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error deleting announcement " + error,
            toastOptions
          );
        });
    },

    /**** Race Results ***/
    lookupRaceAction: (context, payload) => {
      context.state.isLoading = true;
      axios
        .get(apibase + "GetRace/" + payload, phHeaders)
        .then((response) => {
          context.commit("lookupRace", response.data);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Error obtaining race result " + error,
            toastOptions
          );
        });
    },

    /****  Custom Classes ***/
    getRacerClasses: (context) => {
      context.state.isLoading = true;
      axios
        .get(
          apibase +
            "GetRacerClasses?discordId=" +
            context.state.discordUser.discordId,
          phHeaders
        )
        .then((response) => {
          context.commit("setRacerClasses", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error fetching classes " + error, toastOptions);
        });
    },
  },
  modules: {},
});
