<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["ratingHistory"],
  mounted() {
    this.renderChart(this.chartData, {
      scales: {
        xAxes: [{ gridLines: { color: "rgba(255,255,255,0.2)" } }],
        yAxes: [{ gridLines: { color: "rgba(255,255,255,0.2)" } }],
      },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Rating History",
      },
    });
  },

  methods: {
    ratingDates(dates) {
      return dates.map(function(date) {
        return date["ratingDate"];
      });
    },

    ratings(ratings) {
      return ratings.map(function(rating) {
        return rating["visualRating"];
      });
    },
  },
};

// {
//         labels: this.ratingDates(this.ratingHistory),
//         datasets: [
//           {
//             label: "Rating History",
//             data: this.ratings(this.ratingHistory),
//             backgroundColor: "transparent",
//             borderColor: "rgba(1, 116, 188, 0.50)",
//             pointBackgroundColor: "rgba(171, 71,188,1)",
//           },
//         ],
//       },
//       {
//         responsive: true,
//         maintainAspectRatio: false,
//         title: {
//           display: true,
//           text: "Rating History",
//         },
//       }
</script>
