<template>
  <div>
    <Chat />
  </div>
</template>

<script>
// @ is an alias to /src
import Chat from "@/components/ChatLogPage.vue";

export default {
  name: "ChatLog",
  components: {
    Chat,
  },
};
</script>
