
























import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import CustomButton from "@/components/shared/CustomButton.vue";

@Component({
  components: {
    CustomButton,
  },
  computed: mapState([
    "discordUser"
  ]),
})
export default class Settings extends Vue {
  public showStats = false;
  private discordUser: any;

  mounted() {
      this.showStats = this.discordUser.showStats;
    }

  public applyChanges(): void {
    const formData = new FormData();
    formData.append("userGuid", this.$store.state.discordUser.racerGuid);
    formData.append("showStats", this.showStats.toString());

    this.$store.dispatch("UpdateUserSettings", formData);
  }
}
