


















































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import CustomButton from "@/components/shared/CustomButton.vue";

@Component({
  computed: mapState(["vehicleList"]),
  components: {
    CustomButton,
  },
})
export default class Vehicles extends Vue {
  private selectedCategory = "";
  private searchFilter = "";
  private currentSort = "";
  private currentSortDir = "desc";

  mounted(): void {
    this.$store.dispatch("getAllVehicles");
  }

  sort(column: string): void {
    if (column === this.currentSort) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
    }

    this.currentSort = column;
  }

  get categoryList(): string[] {
    let data = new Map();
    for (let vehicle of this.$store.state.vehicleList) {
      data.set(vehicle.vehicleCategory, vehicle.vehicleCategory);
    }

    let unique = [...data.values()];
    unique.sort((a: string, b: string) => (a < b ? -1 : 1));
    return unique;
  }

  resetFilters(): void {
    this.selectedCategory = "";
    this.searchFilter = "";
  }

  get filteredRows(): string[] {
    // eslint-disable-next-line
    let vehicles: any = this.$store.state.vehicleList;

    if (this.selectedCategory != "") {
      vehicles = vehicles.filter(
        (row: {
          vehicleName: { toString: () => string };
          vehicleCategory: { toString: () => string };
        }) => {
          const categoryName = row.vehicleCategory.toString().toLowerCase();
          let lowercat = this.selectedCategory.toLowerCase();
          lowercat = lowercat.replace("(", "\\(");
          lowercat = lowercat.replace(")", "\\)");
          let regex = new RegExp("^" + lowercat + "$");
          return categoryName.match(regex);
        }
      );
    }

    if (this.searchFilter != "") {
      vehicles = vehicles.filter(
        (row: {
          vehicleName: { toString: () => string };
          vehicleCategory: { toString: () => string };
        }) => {
          const trackName = row.vehicleName.toString().toLowerCase();
          const categoryName = row.vehicleCategory.toString().toLowerCase();
          const searchTerm = this.searchFilter.toLowerCase();

          return (
            categoryName.includes(searchTerm) || trackName.includes(searchTerm)
          );
        }
      );
    }

    // eslint-disable-next-line
    return vehicles.sort((a: any, b: any) => {
      let modifier = 1;
      if (this.currentSortDir === "desc") modifier = -1;
      if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
      if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
      return 0;
    });
  }
}
