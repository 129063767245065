<template>
  <div>
    <Bug />
  </div>
</template>

<script>
// @ is an alias to /src
import Bug from "@/components/BugPage.vue";

export default {
  name: "Bugs",
  components: {
    Bug,
  },
};
</script>
