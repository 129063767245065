<template>
  <div>
    <TracksComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import TracksComponent from "@/components/Tracks.vue";

export default {
  name: "Tracks",
  components: {
    TracksComponent,
  },
};
</script>
