







































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import CustomButton from "@/components/shared/CustomButton.vue";

@Component({
  computed: mapState(["trackList"]),
  components: {
    CustomButton,
  },
})

// @Component({
// 	computed: {
// 		sortedTrackList: function(){
// 			return this.$store.state.trackList;
// 		}
// 	}
// })
export default class TrackDetails extends Vue {
  private trackId: string = this.$route.params.trackId;
  // eslint-disable-next-line
  private track: any = [];
  private trackImage = "";
  private trackVideo = "";

  created(): void {
    this.$store.dispatch("getAllTracks");
  }

  findTrack(): void {
    for (var i = 0; i < this.$store.state.trackList.length; i++) {
      if (this.$store.state.trackList[i].id == this.trackId) {
        this.track = this.$store.state.trackList[i];
        let baseimgPath =
          "https://objectstorage.eu-frankfurt-1.oraclecloud.com/p/xmCpD2U-MsovkVtXACMrBT4afwbohA_nia3FdpPfmJqRQKxPEHH_VwtkhpOADxMW/n/frr1enrdwhf0/b/trackMaps/o/";
        let endimagePath = ".jpg?alt=media";
        let imageUrl = baseimgPath + this.track.id + endimagePath;
        this.trackImage = imageUrl;
        this.trackVideo =
          "https://www.youtube-nocookie.com/embed/" + this.track.youtube;
      }
    }
  }
}
